import { Link } from 'react-router-dom'

function BacaanCardComponent({ data }) {
    return (
      <div class="col-sm mx-auto my-2">
        <div class={data.cls + " card"}>
          <div class="card-body">
            <h5 class="card-title text-light">{data.title}</h5>
            <p class="card-text shadowText">{data.desc}</p>
            <Link to={data.lnk} class="btn btn-primary">Baca</Link>
          </div>
        </div>
      </div>
    );
  }

export default BacaanCardComponent
