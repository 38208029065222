import React, {useEffect} from 'react'
import aeromobil from '../../static/images/aeromobil.png'
import airtaxi from '../../static/images/airtaxi.jpg'
import BacaanLain from '../../components/BacaanLain';

function KeretaTerbang() {

    useEffect(() => {
        document.title = "Bilakah Kita Akan Mula Menggunakan Kereta Terbang? - science4derp"
      }, []);

  return (
    <div className="keretaTerbangContainer">
      <div className="container txtContainer mt-5 py-5">
        <p className="interaktifTitle titleTxtLG">Bilakah Kita Akan Mula Menggunakan Kereta Terbang?</p>
        <p className="bodyText">Sejak puluhan tahun yang lalu, manusia telah mula mengimpikan kewujudan kereta terbang, yang berfungsi seperti kapal terbang persendirian. Namun sehingga kehari ini, kita masih belum dapat merealisasikan impian ini. Kereta terbang masih belum dihasilkan secara meluas dan yang ada hanyalah pelbagai jenis prototaip. Ada yang berbentuk seperti kapal terbang, dengan sayapnya yang melebar, dan ada pula yang seperti dron, dengan sayap yang berpusing. Apakah faktor yang menyebabkan teknologi pembangunan kereta terbang seakan – akan sepi dan kurang mendapat perhatian?</p>
        <p className="bodyText">Kalau kita menyusur sejarah penciptaan kapal terbang, dan kereta. Kita dapati bahawa terdapat perbezaan ketara dari segi pembangunannya. Kapal terbang direka supaya ringan, nipis dan berbentuk aerodinamik, bagi menghasilkan daya angkat yang besar. Sebaliknya, kereta pula direka untuk mempunyai keluasan yang besar, dan berat bagi menghasilkan kestabilan untuk kekal berada diatas jalan raya. Maka, perbezaan ini akan menyebabkan kesulitan dalam membina reka bentuk yang terbaik. Mana tidaknya, sudah tentu sayap yang lebih besar atau kuasa rotor yang lebih padu diperlukan sekiranya badan sebuah kereta itu ingin diangkat.</p>
        <img className="img-fluid py-5" src={aeromobil} alt=""/>
        <p className="bodyText">Beberapa syarikat telah pun menyiapkan prototaip bagi kereta terbang. Syarikat AeroMobil dari Slovakia telah menghabiskan lebih 300,000 jam dalam membina sebuah kereta terbang bersayap yang berfungsi dengan baik. setelah melalui 4 fasa penyelidikan dan penambah baikan, mungkinkah kita akan dapat melihat kereta terbang ini bersusuran dijalan dan diawangan? Mungkin agak sukar disebabkan anggaran kos untuk sebuah kereta terbang keluaran syarikat ini adalah kira USD1.3 Juta, dan anda tetap memerlukan lesen juruterbang untuk mengendalikannya.</p>
        <p className="bodyText">Syarikat – syarikat lain seperti Uber, GM dan Hyundai tidak terkecuali dalam pembangunan kereta terbang. Akan tetapi, mereka lebih memfokuskan reka bentuk VTOLs(Vertical Take-Off and Landing aircraft) yang menyerupai helikopter atau dron. Sehingga kini, terdapat banyak prototaip yang telah dibina menggunakan konsep ini, tetapi pada masa kini, ianya lebih tertumpu untuk digunakan sebagai teksi udara, dan ianya juga mempunyai tempat perhentian yang sama seperti sebuah helipad, yang digelar ‘skyports’. Maka secara umumnya, rekaan sebegini tidaklah boleh dikira sebagai ‘kereta’ kerana ianya tidak digunakan diatas jalanraya. Akan tetapi, ada kemungkinan pembangunan skala besar bagi konsep ini yang boleh menandingi penggunaan helikopter mahupun kenderaan lain pada masa akan datang.</p>
        <img className="img-fluid py-5" src={airtaxi} alt=""/>
        <p className="bodyText">Pada pandangan kami, jenis reka bentuk VTOLs ini adalah lebih sesuai dan tampak lebih realistik untuk asimilasi dalam teknologi dunia masa kini. Walaubagaimanapun, disebabkan kosnya yang masih tinggi, disamping memerlukan faktor lain seperti kawalan trafik udara yang lebih ketat, dan kewujudan teknologi pengangkutan lain yang lebih efektif, menyebabkan teknologi ini mungkin memerlukan lebih banyak masa sebelum ianya mula menjadi sebahagian dari kehidupan kita.</p>
        <BacaanLain/>
      </div>
    </div>
  )
}
export default KeretaTerbang
