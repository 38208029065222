import React, {useEffect} from 'react'
import aerofoil from '../../static/images/aerofoil.jpg'
import blowPaper from '../../static/images/blow-paper.png'
import BacaanLain from '../../components/BacaanLain';

function KapalTerbang() {
  useEffect(() => {
    document.title = "Bagaimana Pesawat Mampu Terbang? - science4derp"
  }, []);

  return (
    <div className="sectionsContainer planeContainer">
      <div className="container txtContainer mt-5 py-5">
        <h1 className="bacaanTitle korekTitle" style={{padding:"10px", textAlign: "center"}}>Bagaimana Pesawat Mampu Terbang?</h1>
        <p className="bodyText">Pernahkah anda terfikir, saat anda melihat kapal terbang yang megah berlegaran di atas awan, bagaimana ia mampu mengangkat beban yang begitu berat sedangkan sekiranya kita cuba untuk melompat atau terbang, kita hanya mampu ‘terbang’ beberapa saat sahaja sebelum kita akan kembali keatas tanah. Hal ini disebabkan oleh daya tarikan graviti bumi yang kuat, menarik setiap benda pada permukaannya.</p>
        <p className="bodyText">Maka, dengan melepaskan diri dari tekanan graviti tersebut dapat membuatkan diri kita terapung di awangan. Pesawat mempunyai sayap dan badan yang berbentuk ‘aeofoil’, dimana bentuk ini mampu memberikan daya angkat yang besar.</p>
        <img className="img-fluid" src={aerofoil} alt="lapisan bumi"/>
        <p className="bodyText">Prinsip Bernoulli menerangkan mengenai hubungan diantara halaju dan tekanan bagi bendalir termasuk gas dan cecair. Semakin tinggi kelajuan sesuatu bendalir, semakin rendah tekanannya. Bagi sebuah pesawat, apabila ianya bergerak kehadapan, udara yang mengalir pada bahagian atas sayapnya bergerak lebih laju daripada bahagian bawah. Perbezaan tekanan ini akan menghasilkan daya angkat yang lebih tinggi dari daya graviti dan daya seret, seterusnya membolehkan ia terbang.</p>
        <p className="bodyText">Bumi ini tidaklah dipenuhi dengan tanah sahaja didalamnya. Tanah yang menyelaputi bumi ini hanyalah lapisan luar yang dikenali sebagai kerak bumi. Ianya pula hanya berketebalan diantata 17-50 km sahaja. Malah, struktur kerak bumi ini sendiri terdiri daripada dua jenis lapisan iaitu lapisan atas yang diliputi oleh granit dan lapisan bawah yang terdiri daripada batu keras yang dipanggil basalt. Selepas kerak bumi, kita akan sampai pula kelapisan kedua yang lebih tebal iaitu mantel, dan kemudiannya teras luar dan dalam.</p>
        <p style={{fontFamily: 'Rampart One', fontSize: '1.5em'}}>Jom eksperimen</p>
        <div className='row align-items-center'>
          <div className='col-sm'>
            <p className="bodyText">Bahan yang diperlukan:</p>
            <p className="bodyText">1. sehelai kertas A4</p>
            <p className="bodyText">Aktiviti:</p>
            <p className="bodyText">1. pegang kertas A4 secara melintang dan letakkannya pada pertengahan mulut.</p>
            <p className="bodyText">2. tiup dengan kuat bahagian atas kertas tersebut, anda akan dapati kertas terbang keatas.</p>
          </div>
          <div className='col-sm'>
            <img className="img-fluid" src={blowPaper} alt="lapisan bumi"/>
          </div>
        </div>
        <BacaanLain/>
      </div>
    </div>
  )
}

export default KapalTerbang
