import React, { useEffect, useState } from "react";
import BacaanCardComponent from './BacaanCardComponent'

function BacaanLain() {
  const [result, setResult] = useState([0,1,2]);
  const bacaan = [
    {title:"Sampah Angkasa Raya",desc:"Bukan bumi kita sahaja yang dipenuhi sampah sarap malah ruang angkasa juga tidak terlepas dari sisa buangan!",cls:"sampahBg", lnk:"/bacaan/sampah-angkasa"},
    {title:"Bagaimanakah Bumi tanpa Adanya Bulan?",desc:"Mampukah kita terus hidup dengan aman tanpa bulan?",cls:"bulanBg", lnk:"/bacaan/bulan-hilang"},
    {title:"Haiwan ini Tidur Siang 10,000 Kali Sehari!",desc:"Apakah si gebu ni tak merasa bosan tidur sahaja sepanjang masa?",cls:"penguinBg", lnk:"/bacaan/penguin-tidur"},
    {title:"Sedalam Manakah Kita Mampu Mengorek Bumi?",desc:"Apa yang ada disebalik tanah tempat anda berpijak?",cls:"bumiBg", lnk:"/bacaan/korek-bumi"},
    {title:"Bilakah Kita Akan Mula Menggunakan Kereta Terbang?",desc:"Mungkin desain dron yang menjadi pilihan untuk membawa penumpang?",cls:"futureBg", lnk:"/bacaan/kereta-terbang"},
    {title:"Sistem Solar",desc:"Tentang apa yang ada di sekeliling kita, planet dan bintang di alam raya",cls:"solarBg", lnk:"/bacaan/sistem-solar"},
    {title:"Benarkah Badan Kita Menghasilkan Elektrik?",desc:"Pernahkah anda merasa seperti terkena renjatan elektrik?",cls:"elektrikBg", lnk:"/bacaan/badan-elektrik"},
    {title:"Bagaimana Pesawat Mampu Terbang??",desc:"Pelik kan dengan berat yang bertan tan, ia mampu meluncur di awanan.",cls:"planeBg", lnk:"/bacaan/kapal-terbang"}
  ]
  const randomNumbers = () => {
    let numbers = [];
    while(numbers.length < 3){
        const randomNumber = Math.floor(Math.random() * (8));
        if(!numbers.includes(randomNumber)){
            numbers.push(randomNumber);
        }
    }
    setResult(numbers);
  };

  useEffect(() => {
    randomNumbers()
  }, [])
  
  return (
    <div className="container text-center">
        <h1 className="text-light interaktifTitle">Anda Mungkin Meminati</h1>
        <div class="row">
        <BacaanCardComponent data={bacaan[result[0]]} />
        <BacaanCardComponent data={bacaan[result[1]]} />
        <BacaanCardComponent data={bacaan[result[2]]} />
        </div>
    </div>
  );
}

export default BacaanLain;
