import React, {useEffect} from 'react'
import penguinColony from '../../static/images/penguin-colony.jpg'
import penguinFluff from '../../static/images/penguin-fluff.jpg'
import penguinHatch from '../../static/images/penguin-hatch.jpg'
import penguinSwim from '../../static/images/penguin-swim.jpg'
import BacaanLain from '../../components/BacaanLain'

function PenguinTidur() {

  useEffect(() => {
    document.title = "Haiwan ini Tidur Siang 10,000 Kali Sehari! - science4derp"
  }, []);

  return (
    <div className="penguinContainer">
      <div className="container txtContainer mt-5 py-5">
        <h1 className="bacaanTitle korekTitle" style={{padding:"10px", textAlign: "center"}}>Haiwan ini Tidur Siang 10,000 Kali Sehari!</h1>
        <p className="bodyText">Bayangkan bagaimana kehidupan anda sekiranya anda habiskan dengan tidur siang (take a nap) sebanyak 10,000 kali sehari. Inilah realitinya bagi anak-anak spesis hidupan di antartika iaitu burung penguin <i>Chinstrap</i>, menurut kajian yang dijalankan baru - baru ini.</p>
        <p className="bodyText">Bebola 🐧 gebu yang comel ini menghabiskan lebih banyak masa tidur berbanding makhluk lain di muka bumi, dengan purata tempoh tidur hanya 8 saat setiap kali! Akan tetapi, ternyata tidur sangat diperlukan bagi mereka untuk membesar dengan sihat dan kuat. Semasa mereka mengantuk, badan mereka sibuk membaiki otot, membina tulang dan menyimpan tenaga untuk pengembaraan mereka memburu kelazatan ikan - ikan di lautan yang terbentang luas dihadapan mereka.</p>
        <p className="bodyText">Tetapi jangan risau, anak penguin ini bukanlah pemalas! Mereka juga menghabiskan banyak masa berenang, meneroka dan belajar bersama ibu bapa mereka. Dan sama seperti kita, mereka bangun dengan rasa segar dan bersedia untuk menghadapi dunia.</p>
        <p className="bodyText">Berikut adalah beberapa fakta menarik tentang anak penguin <i>Chinstrap</i>:</p>
        <div>
          <div className='row'>
            <div className='col-sm'>
              <img src={penguinHatch} className="img-fluid rounded-4 border border-dark border-1 w-75" alt="Penguin Menetas"/>
              <p>Mereka menetas dari telur kira-kira saiz tin soda.</p>
            </div>
            <div className='col-sm'>
              <img src={penguinFluff} className="img-fluid rounded-4 border border-dark border-1 w-75" alt="Penguin Comel"/>
              <p>Mereka mempunyai bulu kelabu yang gebu sehingga mereka dewasa.</p>
            </div>
            <div className='col-sm'>
              <img src={penguinSwim} className="img-fluid rounded-4 border border-dark border-1 w-75" alt="Penguin Berenang"/>
              <p>Mereka boleh berenang sepantas 8 km sejam!</p>
            </div>
            <div className='col-sm'>
              <img src={penguinColony} className="img-fluid rounded-4 border border-dark border-1 w-75" alt="Penguin Bersama Kawan"/>
              <p>Mereka tinggal dalam koloni besar sehinggga mencecah 1 juta populasi di Antartika.</p>
            </div>
          </div>
        </div>
        <p className="bodyText">Oleh itu, sekiranya anda merasa mengantuk dalam kesibukan hari yang mendatang, bayangkan penguin montel ini🐧 sebagai bantal anda dan berehatlah sebentar.</p>
        <BacaanLain/>
      </div>
    </div>
  )
}

export default PenguinTidur
