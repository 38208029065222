import React, {useEffect} from 'react'
import familyPortrait from '../../static/images/family-portrait.webp'
import ammonia from '../../static/images/ammonia.webp'
import bagpack from '../../static/images/bagpack.webp'
import camera from '../../static/images/camera.webp'
import lightning from '../../static/images/lightning.webp'
import pizza from '../../static/images/pizza.webp'
import spaceStation from '../../static/images/space-station.webp'
import toilet from '../../static/images/toilet.webp'
import gloves from '../../static/images/gloves.png'
import BacaanLain from '../../components/BacaanLain'

function SampahAngkasa() {

  useEffect(() => {
    document.title = "Sampah Angkasa Raya - science4derp"
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }, []);

  return (
    <div id="angkasaContainer" className="angkasaContainer mt-5 py-5">
      <BacaanLain/>
      <div className='row marg10'>
        <div className='col-9'>
          <p className='saTxt'>Selain kamera, terdapat pelbagai barang - barangan lain yang terlepas di angkasa sama ada secara sengaja ataupun tidak, seperti plastik sampah, jarum peniti, lego malah sayur sayuran dan juga abu mayat manusia!</p>
        </div>
        <div className='col-3'>
          <img src={camera} className="cameraImg rotImg" alt="Camera"/>
        </div>
      </div>
      <div className='row marg10'>
        <div className='col-7'>
          <p className='saTxt'>Ingatkan petir berlaku di bumi je, rupanya para saintis telah menemui petir yang terhasil di Planet Marikh dan Zuhal, malah ia juga boleh berlaku dimana - mana sahaja termasuk diangkasa. Awan yang diterokai saintis di kosmos pula dikatakan mengandungi lebih 140 trilion kali ganda air dari jumlah air yang terdapat dilautan bumi!</p>
        </div>
        <div className='col-5'>
          <img src={lightning} className="lightningImg" alt="Petir"/>
        </div>
      </div>
      <div className='row marg10'>
        <div className='col-3'>
          <img src={familyPortrait} className="familyPortraitImg" alt="Portrait Keluarga"/>
        </div>
        <div className='col-9'>
          <p className='saTxt'>Dalam misi Apollo ke-16, Charles Duke dikatakan meninggalkan potret foto keluarganya pada permukaan bulan sebagai tanda kenangan. Patutkah ianya diklasifikasikan sebagai sampah angkasa?</p>
        </div>
      </div>
      <div className='row marg10'>
        <div className='col'>
          <p className='saTxt'>Dalam tahun 2007, para angkasawan di Stesen Angkasa Internasional (ISS) telah melepaskan beberapa tong Ammonia yg setiap satunya mempunyai berat lebih setengah tan dari stesen tersebut.</p>
        </div>
        <div className='col'>
        <img src={ammonia} className="ammoniaImg rotImg" alt="Tong Ammonia"/>
        </div>
      </div>
      <div className='row marg10'>
        <div className='col'>
          <img src={bagpack} className="bagpackImg" alt="Bag Pack"/>
        </div>
        <div className='col'>
          <p className='saTxt'>Pada November 2008, Angkasawan Heide Stefanyshyn-Piper terlepas begnya yang mengandungi pelbagai alatan bernilai lebih RM4 juta diangkasa raya. Malangnya, beg alatan ini akan terbakar dan hancur sekiranya ia kembali ke bumi, maka tidak ada harapan kalau kita ingin mencarinya sebagai harta karun.</p>
        </div>
      </div>
      <div className='row marg10'>
        <div className='col'>
          <p className='saTxt'>Sarung tangan yang tercabut dari pegangan angkasawan Ed White mungkin sedang mengelilingi bumi pada kelajuan 27,000 km sejam.</p>
        </div>
        <div className='col'>
          <img src={gloves} className="glovesImg" alt="Bag Pack"/>
        </div>
      </div>
      <div className='row marg10'>
        <div className='col-5'>
          <img src={spaceStation} className="spaceStationImg" alt="Space Station"/>
        </div>
        <div className='col-7'>
          <p className='saTxt' style={{zIndex:2}}>Menurut teori konspirasi, ketika Perang Dunia ke-2 sedang meletus, Nazi telah membangunkan sebuah pangkalan di angkasa sebagai tempat berlindung sekiranya mereka tewas dalam peperangan.</p>
        </div>
      </div>
      <div className='row marg10'>
        <div className='col-1 saBuf'></div>
        <div className='col'>
          <img src={pizza} className="pizzaImg" alt="Needle"/>
        </div>
        <div className='col-8'>
          <p className='saTxt'>Pada tahun 2001, Pizza Hut menjadi francais pizza pertama yang membuat penghantaran pizza ke angkasa. Harganya tidak murah, iaitu pada kadar 750,000 pound untuk menghantar pizza kepada angkasawan Yuri Usachov.</p>
        </div>
        <div className='col-1 saBuf'></div>
      </div>
      <div className='row marg10'>
        <div className='col-1 saBuf'></div>
        <div className='col-7'>
          <p className='saTxt'>Menjijikkan! Ruang angkasa dipenuhi dengan segala sisa buangan manusia sebab tidak ada tong sampah diangkasa! Akan tetapi, Ianya membeku menjadi ketulan kristal yang CANTIK(🤮) disebabkan suhu yang terlampau rendah di angkasa.</p>
        </div>
        <div className='col-4'>
          <img src={toilet} className="toiletImg" alt="Toilet"/>
        </div>
      </div>
      <div className='angkasaTxtContainer marg10'>
        <p className='angkasaTxt bg-dark'>Juga dikenali sebagai 'Sampah Angkasa', objek - objek ini sedang mengorbit bumi pada saat ini. Malah, saintis menganggarkan keadaan di angkasa agak padat, dengan jumlah objek - objek yang bergerak mencapai nilai bertrilion.</p>
        <p className='angkasaTxt bg-dark'>Sumber bagi sampah angkasa ini boleh jadi ditinggalkan dari misi angkasa yang lepas, objek - objek yang diluncurkan dari bumi, malah ada juga yang secara tidak sengaja ditinggalkan oleh para angkasawan.</p>
        <p className="angkasaTxt bg-dark">Tahukah anda, terdapat banyak objek buatan manusia yang terapung di angkasa raya?</p>
        <p className='angkasaTxt'>.</p>
        <p className='angkasaTxt'>.</p>
        <p className='angkasaTxt'>.</p>
        <p className='angkasaTxt'>.</p>
      </div>
      <h1 id="titleTxt" className="bacaanTitle korekTitle" style={{padding:"10px", textAlign: "center"}}>Sampah Angkasa</h1>
    </div>
  )
}

export default SampahAngkasa