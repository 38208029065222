import React from 'react'

function Footer() {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="ftrContainer">
    <p className="ftrText">© 2021-{currentYear} science4derp – 003283217-A - Made with ❤️ by <a href="https://fahmijaafar.com">FJ</a></p>
    </footer>
  )
}

export default Footer
