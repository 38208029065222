import React, {useEffect} from 'react'
import lapisanbumi from '../../static/images/lapisanbumi.png'
import BacaanLain from '../../components/BacaanLain';

function KorekBumi() {

  useEffect(() => {
    document.title = "Sedalam manakah kita mampu mengorek bumi? - science4derp"
  }, []);

  return (
    <div className="korekContainer">
      <div className="container txtContainer mt-5 py-5">
        <h1 className="bacaanTitle korekTitle" style={{padding:"10px", textAlign: "center"}}>Sedalam manakah kita mampu mengorek bumi?</h1>
        <p className="bodyText">Pernah tak anda terfikir, apakah yang sebenarnya ada dibawah telapak kaki kita andainya kita menggali sehingga menembusinya. Bumi ini kan berbentuk sfera, mungkinkah dibawah kita sebenarnya ada bangunan kediaman orang lain, atau pantai yang indah, mahupun tengah lautan yang sunyi sepi? Kenapa ya, tidak ada sesiapa pun yang ingin menggali kedalam bumi sehinggalah ianya tembus dibahagian sisi yang satu lagi. Bayangkan sekiranya kita mula menggali kedalam tanah di sekitar Kuala Lumpur, mungkin ianya akan tembus ke Amerika!</p>
        <p className="bodyText">Anda sebenarnya boleh mengetahui apa yang ada dihujung belahan dunia yang ada di bawah tapak kaki anda dengan melayari laman web <a href="https://antipodesmap.com">antipodesmap.com</a> dan melihat sendiri dimanakah tempat yang akan anda tembusi sekiranya anda selesai menggali nanti. Kalau tempat tinggal derp, ianya akan tembus ke sebuah bandar di Ecuador!</p>
        <p className="bodyText">Sebenarnya, telah ada percubaan manusia untuk menggali kedalam perut bumi. Malangnya, sehingga ke hari ini, penggalian tersebut terhenti sehingga kedalaman 12km dibawah tanah sahaja. Itupun pada tahun 1970, dimana Rusia menjalankan projek yang dipanggil Kola Superdeep Borehole. Kenapa hanya 12km? Anda mungkin boleh meneka jawapannya.</p>
        <p className="bodyText">Bumi ini tidaklah dipenuhi dengan tanah sahaja didalamnya. Tanah yang menyelaputi bumi ini hanyalah lapisan luar yang dikenali sebagai kerak bumi. Ianya pula hanya berketebalan diantata 17-50 km sahaja. Malah, struktur kerak bumi ini sendiri terdiri daripada dua jenis lapisan iaitu lapisan atas yang diliputi oleh granit dan lapisan bawah yang terdiri daripada batu keras yang dipanggil basalt. Selepas kerak bumi, kita akan sampai pula kelapisan kedua yang lebih tebal iaitu mantel, dan kemudiannya teras luar dan dalam.</p>
        <img className="img-fluid" src={lapisanbumi} alt="lapisan bumi"/>
        <p className="bodyText">Untuk mencapai ke bahagian teras dalam, kita memerlukan penggalian kira kira 2,400 km yang mana lebih 200 kali ganda daripada apa yang pernah dicapai oleh pihak Rusia. Maka apakah yang menghalang daripada penggalian ini berlaku?</p>
        <p className="bodyText">Menariknya, walaupun kita mungkin hidup selesa dengan kelembapan udara sekitar 32°C, suhu didalam perut bumi adalah amat panas. Semakin dalam penggalian dijalankan, kita akan perlu menghadapi suhu yang lebih panas lagi. Pada kedalaman Kola Superdeep Borehole iaitu lebih kurang 12km, suhunya mencecah 180°C. Suhu ditengah teras dalam pula? Mencapai 5.700°C, atau hampir sama dengan suhu pada permukaan matahari. Jadi sudah pasti pendingin hawa sahaja tidak cukup untuk membantu usaha kita menggali kedalam sana.</p>
        <p className="bodyText">Disamping itu, belum ada lagi teknologi yang mampu untuk digunakan bagi penggalian dikawasan yang bersuhu amat tinggi. Kalaupun ada, tentunya ia mempunyai kos yang sangat besar, dan para ilmuan masih beranggapan tiada jaminan bahawa aktiviti ini akan memberikan impak yang baik kepada kehidupan kita. Maka, mungkin kita akan memerlukan lebih masa lagi sebelum bumi ini ditembusi.</p>
        <BacaanLain/>
      </div>
    </div>
  )
}

export default KorekBumi
