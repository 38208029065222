import React, {useEffect} from 'react'
import eightClock from '../../static/images/8clock.png'
import earthBrown from '../../static/images/earth-brown.png'
import waterSpill from '../../static/images/water-spill.png'
import phoneForecast from '../../static/images/phone-forecast.png'
import shipNight from '../../static/images/ship-night.png'
import lautan from '../../static/images/lautan.png'
import BacaanLain from '../../components/BacaanLain'

function BulanHilang() {

  useEffect(() => {
    document.title = "Bagaimana Bumi tanpa Bulan - science4derp"
  }, []);

  return (
    <div className="bulanContainer">
      <div className="container txtContainer mt-5 py-5">
          <h1 className="bacaanTitle korekTitle" style={{padding:"10px", textAlign: "center"}}>Bagaimanakah Bumi tanpa Adanya Bulan?</h1>
          <h4 className="text-center">Tanpa bulan, adakah kehidupan di atas muka bumi ini?</h4>
          <div>
            <div className='row mt-2'>
              <div className='col-sm'>
                <img src={eightClock} className="img-fluid rounded-4 border border-dark border-1 w-75" alt="Penguin Menetas"/>
                <p>Sehari hanya akan mempunyai 6-8 jam berbanding 24 jam ketika ini.</p>
              </div>
              <div className='col-sm'>
                <img src={earthBrown} className="img-fluid rounded-4 border border-dark border-1 w-75" alt="Penguin Comel"/>
                <p>Dan pertukaran musim bunga, luruh, panas serta sejuk tidak akan wujud sama sekali.</p>
              </div>
              <div className='col-sm'>
                <img src={waterSpill} className="img-fluid rounded-4 border border-dark border-1 w-75" alt="Penguin Berenang"/>
                <p>Tanpa tarikan bulan, putaran bumi akan menjadi lebih laju mengakibatkan kedudukan pada paksinya menjadi tidak stabil.</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm'>
                <img src={phoneForecast} className="img-fluid rounded-4 border border-dark border-1 w-75" alt="Penguin Bersama Kawan"/>
                <p>Ada hari dengan ribut berpanjangan, dan esoknya hari yang tenang. Ramalan kaji cuaca tak dapat berfungsi dengan sempurna.</p>
              </div>
              <div className='col-sm'>
                <img src={shipNight} className="img-fluid rounded-4 border border-dark border-1 w-75" alt="Penguin Bersama Kawan"/>
                <p>Dan, setiap malam sudah pastinya gelap gelita tanpa cahaya dari <i>lampu</i> bulan.</p>
              </div>
              <div className='col-sm'>
                <img src={lautan} className="img-fluid rounded-4 border border-dark border-1 w-75" alt="Penguin Bersama Kawan"/>
                <p>Haiwan juga mungkin akan terus tinggal di dalam lautan selamanya, tanpa pasang surut yang menyebabkan mereka terdampar didaratan.</p>
              </div>
            </div>
          </div>
          <p className="bodyText">Dan anda rasa dapatkah kita tinggal di bumi dengan keadaan seperti ini? Jutaan tahun yang lalu, hampir kesemua hidupan hanya tinggal didalam lautan. Lahar dari gunung berapi didalam laut mengeras lalu membentuk daratan.</p>
          <p className='bodyText'>Tarikan graviti bulan menyebabkan terjadinya pasang surut dan dengan itu beberapa spesis hidupan air mula berevolusi untuk tinggal di daratan. Begitu juga dengan tumbuhan - tumbuhan yang asalnya hidup didalam air.</p>
          <p className='bodyText'>Begitulah besarnya peranan bulan dalam melestarikan ekosistem dunia kita ini. Jadi, macam mana cara kita berterima kasih pada bulan? Dengan menghargai sang penciptanya :D</p>
          <BacaanLain/>
      </div>
    </div>
  )
}

export default BulanHilang
